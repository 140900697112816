.settingsWrapper{
    display: flex;
    min-height: 100%;
    align-items: center;
    flex-direction: column;
    justify-content: center;
}

.closeModalButton{
    font-size: 24px;
}

.settingsFields{
    background-color: rgba(255, 255, 255, 0.292);
    padding: 40px;
    border-radius: 30px;
    margin-bottom: 20px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.25);

}

.submitBtn{
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 20px;
    font-size: 24px;
}

.gameModes{
    display: flex;
    justify-content: space-between;
    font-size: 18px;
}

.active{
    pointer-events: none;
    box-shadow: 0 0 10px 4px #141a40 inset;
}

.active :hover{
    
}

@keyframes UnwrapAnimation {
    from {
        transform: scale(0);

    }

    to {
        transform: scale(1);

    }
}

.settingsFields {
    transform: scale(0);
    animation-duration: 0.2s;
    animation-delay: 0.2s;
    animation-name: UnwrapAnimation;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
    white-space: pre-line;

}

.settingsWrapper {
    opacity: 0;
    animation-duration: 0.2s;
    animation-delay: 0.2s;
    animation-name: opacityAnimation;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
    white-space: pre-line;

}

@keyframes opacityAnimation {
    from {
        opacity: 0;

    }

    to {
        opacity: 1;

    }
}