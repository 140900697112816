.settingsButton{
    display: flex;
    justify-content: flex-end;
    position: relative;
    margin-top: 10px;
    width: 100%;
    font-size: 24px;
    

}

.titleGame{
    position: absolute;
    color: #0015b5cb;
    font-size: 42px;
    
}