.gameWrapper{
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: 100%;
    margin-top: 50px;
}

.gameBtn{
    display: flex;
    justify-content: center;
    width: 100%;
    margin-top: 30px;
    font-size: 28px;
}
.playerBox{
    width: 250px;
    height: 250px;
    background-color: #0015b5cb;
    border-radius: 30px;
    box-shadow: 0 3px 10px rgba(0, 0, 0, 0.25);
    padding: 40px;
    position: relative;
    white-space: nowrap;

}
.titleBox{
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%); 
    font-size: 28px;
}

.gameBox{
    width: 250px;
    height: 250px;
    background-color: #0015b5cb;
    border-radius: 30px;
    box-shadow: 0 3px 10px rgba(0, 0, 0, 0.25);
    padding: 40px;
    position: relative;
    white-space: nowrap;


}

.aiBox{
    width: 250px;
    height: 250px;
    background-color: #0015b5cb;
    border-radius: 30px;
    box-shadow: 0 3px 10px rgba(0, 0, 0, 0.25);
    padding: 40px;
    position: relative;
    white-space: nowrap;

}

.choice{
    background-color: #ffffffff;
    border-radius: 30px;
    height: 100%;
    width: 100%;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 3px 10px rgba(0, 0, 0, 0.25);


}

.numberMatches{
    color: rgb(0, 0, 0);
    font-size: 42px;
    text-overflow: ellipsis;
    overflow: hidden;
}