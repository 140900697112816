.endGameContainer{
    display: flex;
    min-height: 100%;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    font-size: 24px;

}

.winner{
    margin-bottom: 40px;
    font-size: 36px;

}